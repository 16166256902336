export default function ({ store, redirect, app }) {
  // If the user is not authenticated
  const flagAuto4G = app.$cookies.get('flagAuto4G') // get ra local store
  console.log('flagAuto4G out: ', typeof flagAuto4G)
  console.log('authenticated out: ', store.state.authenticated)
  if (store.state.isMobile && !store.state.authenticated && (flagAuto4G === undefined || !flagAuto4G)) {
    // set vao local store
    app.$cookies.set('flagAuto4G', true)
    console.log('flagAuto4G in: ', flagAuto4G)
    return redirect('http://dangky.mobiedu.vn/api/msisdn.jsp?serviceId=71')
    // return redirect('/')
  }
}
