// import {login4g} from '~/common/api/auth'

export default function ({ route, redirect, store }) {
  console.log('start param')
  // Hàm để lấy giá trị tham số "param" từ URL
  if (store.state.needRedirect) {
    // function getParamValueFromURL () {
    //   const urlParams = new URLSearchParams(route.fullPath)
    //   return urlParams.get('param')
    // }
    const paramValue = new URLSearchParams(route.fullPath).get('param')
    console.log('paramValue', paramValue)
    // if (paramValue && paramValue.length > 0) {
    //   login4g(paramValue).then((response) => {
    //     if (response.status === 0) {
    //       store.commit('CLEAR_REDIRECT')
    //       return redirect('/')
    //     }
    //   }).catch((err) => {
    //     console.log(err)
    //   }).finally(() => {})
    // } else {
    //   console.log('no param')
    //   return redirect('/')
    // }
  }
}
